import { css } from '@emotion/css';
import ProductMediaDisplay from 'types/enums/ProductMediaDisplay';

export const productCard = (isCollection: boolean, size: 'small' | 'medium') => css`
  min-height: ${isCollection ? '44rem' : size === 'small' ? '37.4rem' : '43.4rem'};
  overflow: visible;
  margin: 0 auto;
  transition:
    transform 0.3s ease,
    border 0.3s ease;
  &:hover {
    transform: translateY(-1rem);
    outline: 0;
  }
  .collection {
    margin-bottom: -0.1rem;
    height: 27.9rem !important;
  }
`;

export const truncateStyles = (lineClamp: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const imageWrapper = (displayType: ProductMediaDisplay) => css`
  height: 0rem;
  width: 100%;
  padding-bottom: ${displayType === ProductMediaDisplay.Landscape ? '49.43%' : '90%'};
  .imageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    & img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .videoElement {
    video {
      max-width: initial;
      width: auto;
      height: 100%;
    }
  }
`;

export const progressWrapper = (width: number) => css`
  border-radius: 1.2rem;
  .progress {
    width: ${`${width}%`};
    border-radius: 1.2rem;
  }
`;

export const completeIconStyle = css`
  font-size: 2.4rem !important;
`;

export const tagPosition = css`
  top: 23px;
  right: 24px;
`;

export const mediaOverlay = css`
  background: rgba(0, 0, 0, 0.3);
`;

export const countPosition = css`
  bottom: 16px;
  left: 24px;
`;

export const durationPosition = css`
  bottom: 16px;
  right: 24px;
`;
