import _noop from 'lodash/noop';
import useRectPolyFilled from 'use-resize-observer';

import { HookResponse, Options } from './types';

const onLayout = _noop;

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

function useRect<T extends HTMLElement>(opts?: Options<T>): HookResponse<T> {
  const { width, ref, height } = useRectPolyFilled(opts);
  /**
   * onLayout is added to have same parity with mobile native
   */
  return { width, height, ref, onLayout };
}

export default useRect;
